
export default [
  { "code": "ar", "name": { "en": "Argentina" }},
  { "code": "bb", "name": { "en": "Barbados" }},
  { "code": "bo", "name": { "en": "Bolivia" }},
  { "code": "br", "name": { "en": "Brazil" }},
  { "code": "cl", "name": { "en": "Chile" }},
  { "code": "co", "name": { "en": "Colombia" }},
  { "code": "cr", "name": { "en": "Costa Rica" }},
  { "code": "cu", "name": { "en": "Cuba" }},
  { "code": "do", "name": { "en": "Dominican Republic" }},
  { "code": "ec", "name": { "en": "Ecuador" }},
  { "code": "sv", "name": { "en": "El Salvador" }},
  { "code": "gt", "name": { "en": "Guatemala" }},
  { "code": "gy", "name": { "en": "Guyana" }},
  { "code": "ht", "name": { "en": "Haiti" }},
  { "code": "hn", "name": { "en": "Honduras" }},
  { "code": "jm", "name": { "en": "Jamaica" }},
  { "code": "mx", "name": { "en": "Mexico" }},
  { "code": "ni", "name": { "en": "Nicaragua" }},
  { "code": "pa", "name": { "en": "Panama" }},
  { "code": "py", "name": { "en": "Paraguay" }},
  { "code": "pe", "name": { "en": "Peru" }},
  { "code": "pt", "name": { "en": "Portugal" }},
  { "code": "pr", "name": { "en": "Puerto Rico" }},
  { "code": "es", "name": { "en": "Spain" }},
  { "code": "tt", "name": { "en": "Trinidad and Tobago" }},
  { "code": "uy", "name": { "en": "Uruguay" }},
  { "code": "ve", "name": { "en": "Venezuela" }},
  
  { "code": "us", "name": { "en": "United States" }},
  { "code": "eu", "name": { "en": "Europe" }},
]
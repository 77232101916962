export default [
  { "id": 101, "portalId": { "es": 55,  "en": 216, "pt": 217 }, "name": { "en": "Agrotechnology" } },
  { "id": 102, "portalId": { "es": 57,  "en": 214, "pt": 215 }, "name": { "en": "Biotechnology" } },
  { "id": 103, "portalId": { "es": 60,  "en": 208, "pt": 209 }, "name": { "en": "Education" } },
  { "id": 104, "portalId": { "es": 61,  "en": 206, "pt": 207 }, "name": { "en": "Energy" } },
  { "id": 105, "portalId": { "es": 67,  "en": 194, "pt": 195 }, "name": { "en": "Environment" } },
  { "id": 106, "portalId": { "es": 62,  "en": 204, "pt": 205 }, "name": { "en": "Governance" } },
  { "id": 107, "portalId": { "es": 70,  "en": 110, "pt": 189 }, "name": { "en": "Health" } },
  { "id": 108, "portalId": { "es": 271, "en": 281, "pt": 282 }, "name": { "en": "ICT & Electronics" } },
  { "id": 109, "portalId": { "es": 64,  "en": 200, "pt": 201 }, "name": { "en": "Industry" } },
  { "id": 110, "portalId": { "es": 59,  "en": 210, "pt": 211 }, "name": { "en": "Law & Economics" } },
  { "id": 111, "portalId": { "es": 69,  "en": 190, "pt": 191 }, "name": { "en": "Marine Resources" } },
  { "id": 112, "portalId": { "es": 65,  "en": 198, "pt": 199 }, "name": { "en": "Materials" } },
  { "id": 113, "portalId": { "es": 66,  "en": 196, "pt": 197 }, "name": { "en": "Media & Culture" } },
  { "id": 114, "portalId": { "es": 68,  "en": 192, "pt": 193 }, "name": { "en": "Nanotechnology" } },
  { "id": 115, "portalId": { "es": 58,  "en": 212, "pt": 213 }, "name": { "en": "Sciences" } },
  { "id": 116, "portalId": { "es": 63,  "en": 202, "pt": 203 }, "name": { "en": "Social Sciences" } },
  { "id": 117, "portalId": { "es": 273, "en": 283, "pt": 284 }, "name": { "en": "Tourism" } },
  { "id": 118, "portalId": { "es": 272, "en": 285, "pt": 286 }, "name": { "en": "Transport" } },
]
<template>
  <div class="login">

    <form @submit.prevent="login()" class="form" id="login-form">
      <input type="email" placeholder="Email..." aria-label="Email" spellcheck="false" v-model="credentials.email">
      <input type="password" placeholder="Password..." aria-label="Password" spellcheck="false" v-model="credentials.password">
      <button type="submit" class="fluid primary button" form="login-form" :class="{ loading }">Log in</button>
      <div class="error message" v-if="error">        
        <span v-if="error === 'not-authorized'"><i class="fas fa-exclamation-triangle"></i> Wrong email or password</span>
        <span v-else-if="error === 'server-error'"><i class="fas fa-exclamation-triangle"></i> Server error</span>
      </div>
      <router-link :to="{ name: 'reset' }">Forgot your password?</router-link>
    </form>

    <hr/>

    <div class="form">
      <p>Don't have an account?</p>
      <router-link :to="{ name: 'register' }" class="fluid primary button">Register</router-link>
    </div>    

    <router-link :to="{ name: 'home' }"><i class="fas fa-long-arrow-alt-left"></i> Back to home</router-link>

  </div>
</template>

<script>
import APIAccountService from '@/services/APIAccountService'

export default {
  data () {
    return {
      // expired: this.$route.query.expired == 1,
      error: undefined,
      loading: false,
      credentials: {},
    }
  },
  created () {
    this.$store.dispatch('logout');
  },
  methods: {
    login () {
      const { email, password } = this.credentials;
      this.loading = true;
      this.error = undefined;
      APIAccountService.login(email, password)
      .then(({ user, token }) => {
        this.$store.dispatch('login', { user, token });
        this.$router.push({ name: 'logged' }).catch(error => error);
      })
      .catch(res => {
        this.loading = false;
        switch(res.status) {
          case 401: this.error = res.data.error; break;
          default:  this.error = 'server-error'; break;
        }
      });
    },
  },
}
</script>
<template>
  <router-view :data="data"/>
</template>

<script>
export default {
  data () {
    return {
      data: {
        user: {
          email: '',
          password: '',
          name: '',
          surname: '',
          terms: false,
        },
        search: {
          countries: [],
          profiles: [],
          sectors: [],
          topics: [],
          frequencies: [],
        },
        userChecked: false, 
        searchChecked: false, 
      },
    }
  }
}
</script>
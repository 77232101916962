<template>
  <div id="app">
    <the-top-menu/>
    <router-view/>
    <footer>
      <a href="https://www.ovtt.org" target="blank">Go to <strong>OVTT</strong></a>
      <router-link :to="{ name: 'policy-privacy' }">Privacy Policy</router-link>
      <router-link :to="{ name: 'policy-cookies' }">Cookie Policy</router-link>
      <a href="https://www.ua.es" target="blank">University of Alicante</a>
    </footer>
  </div>
</template>

<script>
import TheTopMenu from '@/components/TheTopMenu'

export default {
  components: { TheTopMenu },
}
</script>
<template>
  <div class="reset">

    <div class="steps">
      <div class="step">
        <div class="number"><i class="fas fa-lock"></i></div>
        <div class="title">Credentials</div>
      </div>
      <div class="step active done">
        <div class="number"><i class="fas fa-check"></i></div>
        <div class="title">Confirm</div>
      </div>
    </div>

    <div class="success confirm message">
      <i class="fas fa-check-circle"></i>
      <h2>Congratulations</h2>
      <p>Your password has been reset successfully</p>
    </div>

    <router-link :to="{ name: 'login' }" class="ui primary fluid button">Go to login page</router-link>
    
  </div>
</template>

<script>
export default {
  props: [ 'data' ],
}
</script>
<template>
  <router-view :data="data"/>
</template>

<script>
export default {
  data () {
    return {
      data: {
        credentials: {
          email: '',
          password: '',
        },
        credentialsChecked: false,
      },
    }
  }
}
</script>
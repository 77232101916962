<template>
  <div class="reset">

    <div class="steps">
      <div class="step active">
        <div class="number"><i class="fas fa-lock"></i></div>
        <div class="title">Credentials</div>
      </div>
      <div class="step">
        <div class="number"><i class="fas fa-check"></i></div>
        <div class="title">Confirm</div>
      </div>
    </div>

    <div class="form">
      <div class="field" :class="{ error: errors.fields.email }">
        <label>Email <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="email" placeholder="E-mail..." aria-label="Email" v-model.lazy="credentials.email">
      </div>
      <div class="field" :class="{ error: errors.fields.password }">
        <label>New password <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="password" placeholder="New password..." aria-label="New password" v-model.lazy="credentials.password">
      </div>
      <div class="field">
        <a class="fluid primary button" @click="next()" :class="{ loading }">Continue</a>
      </div>
    </div>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4><i class="fas fa-exclamation-triangle error-icon"></i> The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div> 

    <router-link :to="{ name: 'login' }"><i class="fas fa-long-arrow-alt-left"></i> Back to login page</router-link>

  </div>
</template>

<script>
// import ModalService from '@/services/ModalService'
import APIAccountService from '@/services/APIAccountService'
import ErrorService from '@/services/ErrorService'

// import PrivacyModal from '@/views/membership/PrivacyModal'

export default {
  props: [ 'data' ],
  data () {
    return {
      first: true,
      loading: false,
      errors: ErrorService.init(),
    }
  },
  created () {
    this.$store.dispatch('logout');
  },
  methods: {
    next () {
      this.loading = true;
      APIAccountService.checkCredentials(this.data.credentials)
      .then(() => {
        this.loading = false;
        this.data.credentialsChecked = true;
        this.$router.replace({ name: 'reset-confirm' }).catch(console.error);
      })
      .catch(response => {
        this.first = false;
        this.loading = false;
        this.errors = ErrorService.process(response, this.translateError);
      });
    },
    checkCredentials () {
      APIAccountService.checkCredentials(this.data.credentials)
      .then(() => this.errors = ErrorService.init())
      .catch(response => this.errors = ErrorService.process(response, this.translateError));
    },
    translateError (error) {
      switch(error) {
        case 'email must be valid email': return 'Email is not valid';
        case 'password must contain only letters (a-z) and digits (0-9)': return 'Password must contain only letters (a-z) and digits (0-9)';
        case 'password must have a length between 8 and 20': return 'Password must have a length between 8 and 20';
        default: return error;
      }
    },
  },
  watch: {
    credentials: {
      handler () {
        if(!this.first) {
          this.checkCredentials()
        }
      },
      deep: true,
    }
  },
  computed: {
    credentials () {
      return this.data.credentials;
    }
  }
}
</script>
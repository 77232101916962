import Bye from '../views/Bye.vue'
import Home from '../views/Home.vue'
// import Dashboard from '../views/Dashboard.vue'
import Information from '@/views/Information'
import AccountBase from '@/views/account/AccountBase'
import AccountLogin from '@/views/account/AccountLogin'
import AccountLoginCheck from '@/views/account/AccountLoginCheck'
import AccountRegister from '@/views/account/AccountRegister'
import AccountRegisterUser from '@/views/account/AccountRegisterUser'
import AccountRegisterSearch from '@/views/account/AccountRegisterSearch'
import AccountRegisterConfirm from '@/views/account/AccountRegisterConfirm'
import AccountRegisterDone from '@/views/account/AccountRegisterDone'
import AccountReset from '@/views/account/AccountReset'
import AccountResetCredentials from '@/views/account/AccountResetCredentials'
import AccountResetConfirm from '@/views/account/AccountResetConfirm'
import AccountResetDone from '@/views/account/AccountResetDone'
import Alerts from '@/views/alerts/Alerts'
import Visit from '@/views/Visit'
import Settings from '@/views/settings/Settings'
import SettingsUser from '@/views/settings/SettingsUser'
import SettingsSearch from '@/views/settings/SettingsSearch'
// import SettingsEmail from '@/views/settings/SettingsEmail'
import SettingsPassword from '@/views/settings/SettingsPassword'
import SettingsDelete from '@/views/settings/SettingsDelete'
import PolicyCookies from '@/views/policy/PolicyCookies'
import PolicyPrivacy from '@/views/policy/PolicyPrivacy'
import PolicyBot from '@/views/policy/PolicyBot'
import AdminPromotedList from '@/views/admin/promoted/PromotedList'

export default [
  
  { path: '/', name: 'root', redirect: { name: 'login-check' } },
  { path: '/home', name: 'home', component: Home, meta: { hideNavigation: true }},
  { path: '/info', name: 'info', component: Information},
  { path: '/bye', name: 'bye', component: Bye, meta: { hideNavigation: true }},
  { path: '/logged', name: 'logged', redirect: { name: 'login-check' } },

  { path: '/policy/cookies', name: 'policy-cookies', component: PolicyCookies },
  { path: '/policy/privacy', name: 'policy-privacy', component: PolicyPrivacy },
  { path: '/policy/bot', name: 'policy-bot', component: PolicyBot },
  
  { path: '/account', component: AccountBase, children: [
    { path: '', name: 'account', redirect: { name: 'login' }},
    { path: 'login', name: 'login', component: AccountLogin, meta: { hideNavigation: true }},
    { path: 'register', component: AccountRegister, children: [
      { path: '', name: 'register', redirect: { name: 'register-user' }},
      { path: 'user', name: 'register-user', component: AccountRegisterUser, meta: { hideNavigation: true }},
      { path: 'search', name: 'register-search', component: AccountRegisterSearch, meta: { hideNavigation: true }},
      { path: 'confirm', name: 'register-confirm', component: AccountRegisterConfirm, meta: { hideNavigation: true }},
      { path: 'done', name: 'register-done', component: AccountRegisterDone, meta: { hideNavigation: true }},
    ]},
    { path: 'reset', component: AccountReset, children: [
      { path: '', name: 'reset', redirect: { name: 'reset-credentials' }},
      { path: 'credentials', name: 'reset-credentials', component: AccountResetCredentials, meta: { hideNavigation: true }},
      { path: 'confirm', name: 'reset-confirm', component: AccountResetConfirm, meta: { hideNavigation: true }},
      { path: 'done', name: 'reset-done', component: AccountResetDone, meta: { hideNavigation: true }},
    ]},
    { path: 'check', name: 'login-check', component: AccountLoginCheck, meta: { hideNavigation: true }},
  ]},

  // { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true }},
  { path: '/dashboard', name: 'dashboard', redirect: { name: 'alerts' }, meta: { requiresAuth: true }},
  { path: '/alerts', name: 'alerts', component: Alerts, meta: { requiresAuth: true }},

  { path: '/visit/:code', name: 'visit', component: Visit }, 

  { path: '/settings', component: Settings, children: [
    { path: '', name: 'settings', redirect: { name: 'settings-user' }, meta: { requiresAuth: true }},
    { path: 'user', name: 'settings-user', component: SettingsUser, meta: { requiresAuth: true }},
    { path: 'search', name: 'settings-search', component: SettingsSearch, meta: { requiresAuth: true }},
    // { path: 'email', name: 'settings-email', component: SettingsEmail, meta: { requiresAuth: true }},
    { path: 'password', name: 'settings-password', component: SettingsPassword, meta: { requiresAuth: true }},
    { path: 'delete', name: 'settings-delete', component: SettingsDelete, meta: { requiresAuth: true }},
  ]},

  { path: '/admin/promoted', name: 'promoted', component: AdminPromotedList, meta: { requiresAuth: true, onlyAdmin: true }},
  
  { path: '*', name: 'catchall', redirect: { name: 'login-check' } },

]
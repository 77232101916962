
import axios from 'axios'
import sectors from '@/assets/js/sectors'
import topics from '@/assets/js/topics'

export default {

  import ( importId ) {
    return new Promise((resolve, reject) => {
      // TODO: Parameterize
      axios.get( 'https://www.ovtt.org/api/observa/' + importId + '/', {transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
      }})
      .then(res => {
        const sectorsMap = {};
        const topicsMap = {};
        for(const sector of sectors) {
          for(const id of Object.values(sector.portalId)) {
            sectorsMap[id] = sector.id;
          }
        }
        for(const topic of topics) {
          for(const id of Object.values(topic.portalId)) {
            topicsMap[id] = topic.id;
          }
        }
        const result = {
          title: res.data.title,
          url: res.data.url,
          summary: res.data.summary,
          last: res.data.date,
          sectors: [],
          topics: [],
          countries: [],
          dates: [],
          force: false,
        };
        for(const categoryId of res.data.categoriesId) {
          const sectorId = sectorsMap[categoryId];
          const topicId = topicsMap[categoryId];
          if (sectorId) result.sectors.push(sectorId);
          if (topicId) result.topics.push(topicId);
        }
        console.log(result);
        resolve(result);
      })
      .catch(res => reject(res.response));
    });
  },
  
}

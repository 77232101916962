<template>
  <div class="password">

    <h1>Change password</h1>

    <div class="form">
      <div class="field" :class="{ error: errors.fields.current_password }">
        <label>Current password <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="password" placeholder="Current password..." v-model.lazy="credentials.current_password">
      </div>
      <div class="field" :class="{ error: errors.fields.new_password }">
        <label>New password <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="password" placeholder="New password..." v-model.lazy="credentials.new_password">
      </div>
    </div>

    <a class="primary fluid button" @click="save()" :class="{ loading }">Save</a>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4><i class="fas fa-exclamation-triangle error-icon"></i> The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

    <div class="toast success message" :class="{ visible: success }">
      <h4><i class="fas fa-check"></i> Changes saved successfully</h4>
    </div>

  </div>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import APISettingsService from '@/services/APISettingsService'

export default {
  data () {
    return {
      loading: false,
      credentials: {
        current_password: '',
        new_password: '',
      },
      errors: ErrorService.init(),
      success: false,
      successTimeout: undefined,
    }
  },
  methods: {
    save () {
      this.loading = true;
      this.success = false;
      clearTimeout(this.successTimeout);
      APISettingsService.updatePassword(this.credentials)
        .then(() => {
          this.errors = ErrorService.init(),
          this.loading = false;
          this.success = true;
          this.successTimeout = setTimeout(() => this.success = false, 2000)
        })
        .catch(response => {
          this.errors = ErrorService.process(response, this.translateError)
          this.loading = false;
        });
    },
    translateError (error) {
      switch(error) {
        case 'current_password must contain only letters (a-z) and digits (0-9)': return 'Current password must contain only letters (a-z) and digits (0-9)';
        case 'current_password must have a length between 8 and 20': return 'Current password must have a length between 8 and 20';
        case 'new_password must contain only letters (a-z) and digits (0-9)': return 'New password must contain only letters (a-z) and digits (0-9)';
        case 'new_password must have a length between 8 and 20': return 'New password must have a length between 8 and 20';
        case 'user-password-incorrect': return 'Incorrect current password';
        default: return error;
      }
    },
  }
}
</script>
<template>
  <div class="register">

    <div class="steps">
      <div class="step active">
        <div class="number"><i class="fas fa-user"></i></div>
        <div class="title">Personal data</div>
      </div>
      <div class="step">
        <div class="number"><i class="fas fa-tasks"></i></div>
        <div class="title">Preferences</div>
      </div>
      <div class="step">
        <div class="number"><i class="fas fa-check"></i></div>
        <div class="title">Confirm</div>
      </div>
    </div>

    <div class="form">
      <div class="field" :class="{ error: errors.fields.name }">
        <label>Name <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Name..." aria-label="Name" v-model.lazy="user.name">
      </div>
      <div class="field" :class="{ error: errors.fields.surname }">
        <label>Surname <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Surname..." aria-label="Surname" v-model.lazy="user.surname">
      </div>
      <div class="field" :class="{ error: errors.fields.email }">
        <label>Email <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="email" placeholder="E-mail..." aria-label="Email" v-model.lazy="user.email">
      </div>
      <div class="field" :class="{ error: errors.fields.password }">
        <label>Password <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="password" placeholder="Password..." aria-label="Password" v-model.lazy="user.password">
      </div>
      <div class="field" :class="{ error: errors.fields.terms }">
        <checkbox v-model="user.terms">I have read and I accept the <router-link :to="{ name: 'policy-privacy' }" target="_blank">terms and conditions</router-link> <i class="fas fa-exclamation-triangle error-icon"></i></checkbox>
      </div>
      <div class="field">
        <a class="fluid primary button" @click="next()" :class="{ loading }">Continue</a>
      </div>
    </div>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4><i class="fas fa-exclamation-triangle error-icon"></i> The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

    <hr/>

    <div class="form">
      <p>Already have an account?</p>
      <router-link :to="{ name: 'login' }" class="fluid primary button">Log in</router-link>
    </div>   
    
    <router-link :to="{ name: 'home' }"><i class="fas fa-long-arrow-alt-left"></i> Back to home</router-link>

  </div>
</template>

<script>
// import ModalService from '@/services/ModalService'
import APIAccountService from '@/services/APIAccountService'
import ErrorService from '@/services/ErrorService'
import Checkbox from '@/components/Checkbox'

// import PrivacyModal from '@/views/membership/PrivacyModal'

export default {
  components: { Checkbox },
  props: [ 'data' ],
  data () {
    return {
      first: true,
      loading: false,
      errors: ErrorService.init(),
    }
  },
  created () {
    this.$store.dispatch('logout');
  },
  methods: {
    next () {
      this.loading = true;
      APIAccountService.checkUser(this.data.user)
      .then(() => {
        this.loading = false;
        this.data.userChecked = true;
        this.$router.replace({ name: 'register-search' }).catch(console.error);
      })
      .catch(response => {
        this.first = false;
        this.loading = false;
        this.errors = ErrorService.process(response, this.translateError);
      });
    },
    checkUser () {
      APIAccountService.checkUser(this.data.user)
      .then(() => this.errors = ErrorService.init())
      .catch(response => this.errors = ErrorService.process(response, this.translateError));
    },
    translateError (error) {
      switch(error) {
        case 'name must be a string': return 'Name must not be empty';
        case 'name must not be empty': return 'Name must not be empty';
        case 'email must be valid email': return 'Email is not valid';
        case 'email-unavailable': return 'Email is already in use';
        case 'password must contain only letters (a-z) and digits (0-9)': return 'Password must contain only letters (a-z) and digits (0-9)';
        case 'password must have a length between 8 and 20': return 'Password must have a length between 8 and 20';
        case 'terms is not considered as "True"': return 'Terms and conditions must be accepted';
        default: return error;
      }
    },
  },
  watch: {
    user: {
      handler () {
        if(!this.first) {
          this.checkUser()
        }
      },
      deep: true,
    }
  },
  computed: {
    user () {
      return this.data.user;
    }
  }
}
</script>
export default [
  { "id": 201, "portalId": { "es": 1080, "en": 1150, "pt": 1151 }, "name": { "en": "Cooperation" } },
  { "id": 202, "portalId": { "es": 1077, "en": 1158, "pt": 1159 }, "name": { "en": "Entrepreneurship" } },
  { "id": 203, "portalId": { "es": 1081, "en": 1148, "pt": 1149 }, "name": { "en": "Funding" } },
  { "id": 204, "portalId": { "es": 1082, "en": 1146, "pt": 1147 }, "name": { "en": "Innovation" } },
  { "id": 205, "portalId": { "es": 1075, "en": 1163, "pt": 1164 }, "name": { "en": "Intellectual Property" } },
  // { "id": 206, "portalId": { "es": 1079, "en": 1153, "pt": 1155 }, "name": { "en": "Marketing Technology" } }, # Integrada en Technology Transfer (206 -> 210)
  { "id": 207, "portalId": { "es": 1078, "en": 1156, "pt": 1157 }, "name": { "en": "News & events" } },
  { "id": 208, "portalId": { "es": 1076, "en": 1160, "pt": 1161 }, "name": { "en": "Research" } },
  { "id": 209, "portalId": { "es": 1176, "en": 1623, "pt": 1624 }, "name": { "en": "Technology Intelligence" } },
  { "id": 210, "portalId": { "es": 1074, "en": 1165, "pt": 1166 }, "name": { "en": "Technology Transfer" } },
  { "id": 211, "portalId": { "es": 1083, "en": 1143, "pt": 1145 }, "name": { "en": "Training" } },
  
  { "id": 212, "portalId": { "es": 9404, "en": 9401 }, "name": { "en": "Public Engagement" } },
  { "id": 213, "portalId": { "es": 9402, "en": 9403 }, "name": { "en": "Indicators" } },
]
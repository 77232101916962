<template>
  <div class="user">

    <h1>Personal data</h1>

    <div class="form" :class="{ loading: loadingInit }">
      <div class="field" :class="{ error: errors.fields.name }">
        <label>Name <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Name..." v-model.lazy="user.name">
      </div>
      <div class="field" :class="{ error: errors.fields.surname }">
        <label>Surname <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Surname..." v-model.lazy="user.surname">
      </div>
      <div class="field" :class="{ error: errors.fields.occupation }">
        <label>Occupation <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Occupation..." v-model.lazy="user.occupation">
      </div>
      <div class="field select" :class="{ error: errors.fields.language }">
        <label>Language <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <select v-model="user.language" required>
          <option hidden selected disabled value="">Select language...</option>
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="pt">Portuguese</option>
        </select>
      </div>
      <div class="field select" :class="{ error: errors.fields.country }">
        <label>Country <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <select id="country" v-model="user.country" required>
          <option hidden selected value="">Select country...</option>
          <option :value="country.code" v-for="country in countriesI18N" :key="country.code">{{ country.name }}</option>
        </select>
      </div>
      <div class="field" :class="{ error: errors.fields.organization }">
        <label>Organization <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Organization..." v-model.lazy="user.organization">
      </div>
      <div class="field" :class="{ error: errors.fields.site }">
        <label>Site <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="text" placeholder="Site..." v-model.lazy="user.site">
      </div>
      <div class="field" :class="{ error: errors.fields.bio }">
        <label>Bio <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <textarea placeholder="Something about you..." v-model.lazy="user.bio"></textarea>
      </div>
    </div>

    <a class="primary fluid button" @click="save()" :class="{ loading: loadingSave }">Save</a>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4><i class="fas fa-exclamation-triangle error-icon"></i> The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

    <div class="toast success message" :class="{ visible: success }">
      <h4><i class="fas fa-check"></i> Changes saved successfully</h4>
    </div>

  </div>
</template>

<script>
import countries from '../../assets/js/countries'
import ErrorService from '@/services/ErrorService'
import APISettingsService from '@/services/APISettingsService'

export default {
  data () {
    return {
      countries,
      language: 'en',
      loadingInit: true,
      loadingSave: false,
      first: true,
      user: {},
      errors: ErrorService.init(),
      success: false,
      successTimeout: undefined,
    }
  },
  created () {
    this.reload();
  },
  methods: {
    reload () {
      this.loadingInit = true;
      APISettingsService.getUser()
        .then(user => {
          this.user = user;
          this.user.language = this.user.language || '';
          this.user.country = this.user.country || '';
          this.errors = ErrorService.init()
          this.loadingInit = false;
        })
        .catch(console.error);
    },
    save () {
      this.loadingSave = true;
      this.success = false;
      clearTimeout(this.successTimeout);
      APISettingsService.updateUser(this.user)
        .then(user => {
          this.user = user;
          this.user.language = this.user.language || '';
          this.user.country = this.user.country || '';
          this.loadingSave = false;
          this.$store.dispatch('update', { user: { name: user.name, surname: user.surname }});
          this.success = true;
          this.successTimeout = setTimeout(() => this.success = false, 2000)
        })
        .catch(response => {
          this.errors = ErrorService.process(response, this.translateError)
          this.loadingSave = false;
          this.first = false;
        });
    },
    checkUser () {
      APISettingsService.checkUser(this.user)
        .then(() => this.errors = ErrorService.init())
        .catch(response => this.errors = ErrorService.process(response, this.translateError));
    },
    translateError (error) {
      switch(error) {
        case 'name must be a string': return 'Name must not be empty';
        case 'name must not be empty': return 'Name must not be empty';
        case 'site must be a URL': return 'Site URL is invalid';
        case 'language must be in { "es", "en", "pt" }': return 'Language must not be empty';
        case 'country must be in { "ar", "bb", "bo", "br", "cl", "co", "cr", "cu", "do", "ec", ... }': return 'Country must not be empty';
        default: return error;
      }
    },
  },
  computed: {
    countriesI18N () {
      const countriesI18N = [];
      this.countries.forEach(country => countriesI18N.push({ code: country.code, name: country.name[this.language] }));
      return countriesI18N.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
  },
  watch: {
    user: {
      handler () {
        if(!this.first) {
          this.checkUser();
        }
      },
      deep: true,
    }
  },
}
</script>
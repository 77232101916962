<template>
  <div class="select-buttons" :class="{ full: isFull() }">
    <a v-for="option in options" :key="option[fieldId]" :class="{ active: isActive(option[fieldId]) }" @click="toggle(option[fieldId])">{{ option[fieldName] }}</a>
  </div>
</template>

<script>
export default {
  name: 'CheckButton',
  props: {
    fieldId: { type: String, default: 'id' },
    fieldName: { type: String, default: 'name' },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 1000 },
    options: Array,
    value: Array,
  },
  methods: {
    isActive( optionId ) {
      return this.value && this.value.includes(optionId);
    },
    isFull () {
      return this.value && this.value.length >= this.max;
    },
    toggle( optionId ) {
      const index = this.value.indexOf(optionId);
      if (index > -1) {
        this.value.splice(index, 1);
      } else {
        if(!this.value || this.value.length < this.max) {
          if(!this.value) {
            this.value = [];
          }
          this.value.push(optionId);
        }
      }
    }
  },
  computed: {
    // full: () => ,
  },
}
</script>

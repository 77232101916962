import axios from 'axios'

import APIService from '@/services/APIService'

export default {

  login ( email, password ) {
    return new Promise((resolve, reject) => {
      axios.post( '/account/login', { email, password }, {} )
      .then(res => {
        let token = res.headers['access-token'];
        let user = res.data;
        resolve({ user, token });
      })
      .catch(res => reject(res.response));
    });
  },

  checkUser ( user ) {
    return APIService.post( '/account/register/checkUser', user );
  },

  checkSearch ( search ) {
    return APIService.post( '/account/register/checkSearch', search );
  },

  sendRegisterConfirmation ( user ) {
    return APIService.post( '/account/register/sendConfirmation', user );
  },

  register ( user ) {
    return APIService.post( '/account/register', user );
  },

  checkCredentials ( credentials ) {
    return APIService.post( '/account/reset/checkCredentials', credentials );
  },

  sendResetConfirmation ( user ) {
    return APIService.post( '/account/reset/sendConfirmation', user );
  },

  reset ( credentials ) {
    return APIService.post( '/account/reset', credentials );
  },

}

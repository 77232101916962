
import APIService from '@/services/APIService'

export default {

  getUser () {
    return APIService.get( '/settings/user' );
  },

  updateUser ( user ) {
    return APIService.put( '/settings/user', user );
  },

  checkUser ( user ) {
    return APIService.post( '/settings/user/check', user );
  },

  getSearch () {
    return APIService.get( '/settings/search' );
  },

  updateSearch ( search ) {
    return APIService.put( '/settings/search', search );
  },

  checkSearch ( search ) {
    return APIService.post( '/settings/search/check', search );
  },

  updatePassword ( credentials ) {
    return APIService.post( '/settings/password', credentials );
  },

  deleteAccount ( credentials ) {
    return APIService.post( '/settings/delete', credentials );
  },

}

import Vue from 'vue'

var currentModal = undefined;
    
export default {

  show ( component, data, callback, store ) {
    const ModalComponent = Vue.extend( component );
    const modalInstance = new ModalComponent({ propsData: data, store });
    const modalDimmer = document.createElement('div');
    const modalOverlay = document.createElement('div');
    modalDimmer.classList.add('modal-dimmer');
    modalOverlay.classList.add('modal-overlay');
    modalInstance.$mount();
    modalDimmer.style.opacity = 0;
    modalDimmer.style.transition = 'opacity .2s';
    modalDimmer.append(modalOverlay);
    modalOverlay.append(modalInstance.$el);
    currentModal = { element: modalDimmer, callback };
    document.body.append(currentModal.element);
    setTimeout(() => modalDimmer.style.opacity = 1);
  },

  success ( data ) {
    if ( currentModal && currentModal.callback ) currentModal.callback( data );
    currentModal.element.style.opacity = 0;
    setTimeout(() => {
      document.body.removeChild(currentModal.element);
      currentModal = undefined;
    }, 200);
  },

  hide () {
    if ( currentModal ) {
      currentModal.element.style.opacity = 0;
      setTimeout(() => {
        document.body.removeChild(currentModal.element);
        currentModal = undefined;
      }, 200);
    }
  },

  isOpen () {
    return currentModal;
  },

    // show ( component, data, callback, store ) {

    //     const ModalComponent = Vue.extend( component );
    //     const modalInstance = new ModalComponent({ propsData: data, store });
    //     modalInstance.$mount();
    //     currentModal = $(modalInstance.$el);
    //     currentModal.modal({
    //         closable: false,
    //         transition: 'fade',
    //         duration: 200,
    //         autofocus: false,
    //         onHidden: function() {
    //             currentModal.remove();
    //             currentModal = undefined;
    //             currentModalCallback = undefined;
    //         },
    //         onApprove: function() {
    //             return false;
    //         }
    //     });
    //     currentModal.modal('show');
    //     currentModalCallback = callback;
    // },

    // success ( data ) {
    //     if(currentModalCallback) currentModalCallback(data);
    //     currentModal.modal('hide');
    // },

    // hide () {
    //     if(currentModal !== undefined) {
    //         currentModal.modal('hide');
    //     }
    // },

    // refresh () {
    //     if(currentModal !== undefined) {
    //         currentModal.modal('refresh');
    //     }
    // },

    // isOpen () {
    //     return currentModal !== undefined;
    // },

}

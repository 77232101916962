<template>
  <div class="search">

    <h1>Preferences</h1>

    <div class="form" :class="{ loading: loadingInit }">
      <div class="title" :class="{ error: errors.fields.sectors }">
        <h3>Activity sectors <i class="fas fa-exclamation-circle error-icon"></i></h3>
      </div>
      <div class="field">
        <select-buttons v-model="search.sectors" :options="sectorsI18N" :max="3"/>
      </div>
    </div>

    <div class="form" :class="{ loading: loadingInit }">
      <div class="title" :class="{ error: errors.fields.topics }">
        <h3>Topics of interest <i class="fas fa-exclamation-circle error-icon"></i></h3>
      </div>
      <div class="field">
        <select-buttons v-model="search.topics" :options="topicsI18N" :max="3"/>
      </div>
    </div>

    <!-- <div class="form" :class="{ loading: loadingInit }">
      <div class="title" :class="{ error: errors.fields.countries }">
        <h3>Countries of interest <i class="fas fa-exclamation-circle error-icon"></i></h3>
      </div>
      <div class="field">
        <select-buttons v-model="search.countries" :options="countriesI18N" :field-id="'code'" :max="3"/>
      </div>
    </div> -->

    <div class="form" :class="{ loading: loadingInit }">
      <div class="title" :class="{ error: errors.fields.profiles }">
        <h3>Profile <i class="fas fa-exclamation-circle error-icon"></i></h3>
      </div>
      <div class="field">
        <select-buttons v-model="search.profiles" :options="profilesI18N" :max="1"/>
      </div>
    </div>

    <div class="form" :class="{ loading: loadingInit }">
      <div class="title" :class="{ error: errors.fields.frequencies }">
        <h3>Email frequency <i class="fas fa-exclamation-circle error-icon"></i></h3>
      </div>
      <div class="field">
        <select-buttons v-model="search.frequencies" :options="frequenciesI18N" :max="1"/>
      </div>
    </div>
      
    <a class="ui primary fluid button" @click="save()" :class="{ loading: loadingSave }">Save</a>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4>The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

    <div class="toast success message" :class="{ visible: success }">
      <h4><i class="fas fa-check"></i> Changes saved successfully</h4>
    </div>
    
  </div>
</template>

<script>
import countries from '../../assets/js/countries'
import profiles from '../../assets/js/profiles'
import sectors from '../../assets/js/sectors'
import topics from '../../assets/js/topics'
import frequencies from '../../assets/js/frequencies'

import APISettingsService from '@/services/APISettingsService'
import ErrorService from '@/services/ErrorService'
import SelectButtons from '@/components/SelectButtons'

export default {
  components: { SelectButtons },
  data () {
    return {
      first: true,
      language: 'en',
      loadingInit: false,
      loadingSave: false,
      countries, sectors, topics, profiles, frequencies,
      errors: ErrorService.init(),
      search: {},
      success: false,
      successTimeout: undefined,
    }
  },
  created () {
    this.reload();
  },
  methods: {
    reload () {
      this.loadingInit = true;
      APISettingsService.getSearch()
        .then(search => {
          this.search = search;
          this.errors = ErrorService.init()
          this.loadingInit = false;
        });
    },
    save () {
      this.loadingSave = true;
      this.success = false;
      clearTimeout(this.successTimeout);
      APISettingsService.updateSearch(this.search)
        .then(search => {
          this.search = search;
          this.loadingSave = false;
          this.success = true;
          this.successTimeout = setTimeout(() => this.success = false, 2000)
        })
        .catch(response => {
          this.errors = ErrorService.process(response, this.translateError)
          this.loadingSave = false;
          this.first = false;
        });
    },
    checkSearch () {         
      APISettingsService.checkSearch(this.search)
        .then(() => this.errors = ErrorService.init())
        .catch(response => this.errors = ErrorService.process(response, this.translateError));
    },
    translateError (error) {
      switch(error) {
        case 'countries must have a length lower than 3': return 'Choose up to 3 countries';
        case 'profiles must have a length greater than 1': return 'Choose one profile';
        case 'sectors must have a length between 1 and 3': return 'Choose at least 1 sector and up to 3 sectors';
        case 'topics must have a length lower than 3': return 'Choose up to 3 topics';
        default: return error;
      }
    },
  },
  computed: {
    sectorsI18N () {
      const sectors = [];
      this.sectors.forEach(sector => sectors.push({ id: sector.id, name: sector.name[this.language] }));
      return sectors.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    topicsI18N () {
      const topics = [];
      this.topics.forEach(topic => topics.push({ id: topic.id, name: topic.name[this.language] }));
      return topics.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    countriesI18N () {
      const countries = [];
      this.countries.forEach(country => countries.push({ code: country.code, name: country.name[this.language] }));
      return countries.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    profilesI18N () {
      const profiles = [];
      this.profiles.forEach(profile => profiles.push({ id: profile.id, name: profile.name[this.language] }));
      return profiles.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    frequenciesI18N () {
      const frequencies = [];
      this.frequencies.forEach(frequency => frequencies.push({ id: frequency.id, name: frequency.name[this.language] }));
      return frequencies.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
  },
  watch: {
    search: {
      handler () {
        if(!this.first) {
          this.checkSearch()
        }
      },
      deep: true,
    }
  },
}
</script>

import APIService from '@/services/APIService'

export default {

  getPromotedList ( type ) {
    const params = { type };
    return APIService.get( '/promoted', params );
  },

  updateLike ( promotedId, like ) {
    return APIService.put( '/promoted/' + promotedId + '/like', { like } );
  },

  visit ( code ) {
    return APIService.get( '/visit/' + code );
  },

}

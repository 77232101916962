<template>
  <nav id="topmenu" v-if="!$route.meta.hideNavigation">
    <ul>
      <li class="logo">
        <router-link :to="{ name: user.name ? 'dashboard' : 'home' }">
          <img src="@/assets/img/observa-top.png" alt="Observa">
        </router-link>
      </li>
      <li v-if="user.name"><span>Hello, {{ user.name }}</span></li>
      <li v-if="user.name"><router-link :to="{ name: 'dashboard' }">Alerts</router-link></li>
      <li v-if="user.admin"><router-link :to="{ name: 'promoted' }">Schedule</router-link></li>
      <li v-if="user.name"><router-link :to="{ name: 'settings' }">Settings</router-link></li>
      <li v-if="user.name"><router-link :to="{ name: 'login' }" title="Log out"><i class="fas fa-sign-out-alt"></i></router-link></li>
      <li v-if="!user.name"><router-link :to="{ name: 'login' }">Log in</router-link></li>
      <li v-if="!user.name"><router-link :to="{ name: 'register' }">Register</router-link></li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TopMenu',
  computed: {
    ...mapGetters({ 
      user: 'getUser',
    }),
  },
}
</script>
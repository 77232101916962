
import APIService from '@/services/APIService'

export default {

  getPromotedList ( date, sort ) {
    const params = { date, sort };
    return APIService.get( '/admin/promoted', params );
  },

  getPromotedHistogram ( month, year ) {
    const params = { month, year };
    return APIService.get( '/admin/promotedHistogram', params );
  },

  getPromoted ( promotedId ) {
    return APIService.get( '/admin/promoted/' + promotedId );
  },

  addPromoted( promoted ) {
    return APIService.post( '/admin/promoted', promoted );
  },

  updatePromoted( promotedId, promoted ) {
    return APIService.put( '/admin/promoted/' + promotedId, promoted );
  },

  deletePromoted( promotedId ) {
    return APIService.delete( '/admin/promoted/' + promotedId );
  },

}

<template>
  <div class="delete">

    <h1>Delete account</h1>

    <div class="form" :class="{ disabled: deleteButton }">     
      <div class="field">
        <label>If you need it, you can delete your account at any time. If you just don't want to receive notifications, you can adjust it your preferences.</label>
      </div> 
      <div class="field">
        <a class="fluid danger button" @click="showDeleteButton()">Delete account</a>
      </div>
    </div>

    <div class="form" v-if="deleteButton">
      <div class="field">
        <label>Are you sure you want to delete your account? This operation cannot be undone.</label>
      </div>
      <div class="field" :class="{ error: errors.fields.password }">
        <label>Password <i class="fas fa-exclamation-triangle error-icon"></i></label>
        <input type="password" placeholder="Password..." v-model.lazy="credentials.password">
      </div>
      <div class="field">
        <a class="fluid danger button" @click="deleteAccount()">Yes, I am sure, delete account</a>
      </div>
      <div class="field" @click="hideDeleteButton()">
        <a class="fluid button">Cancel</a>
      </div>
    </div>

    <div class="error message" v-if="errors.messages.length > 0">
      <h4><i class="fas fa-exclamation-triangle error-icon"></i> The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

  </div>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import APISettingsService from '@/services/APISettingsService'

export default {
  data () {
    return {
      deleteButton: false,
      credentials: {
        password: '',
      },
      errors: ErrorService.init(),
    }
  },
  methods: {
    showDeleteButton () {
      this.deleteButton = true;
    },
    hideDeleteButton () {
      this.deleteButton = false;
    },
    deleteAccount () {
      this.loading = true;
      APISettingsService.deleteAccount(this.credentials)
        .then(() => {
          this.$router.push({ name: 'bye' });
          this.loading = false;
        })
        .catch(response => {
          this.errors = ErrorService.process(response, this.translateError)
          this.loading = false;
        });
    },
    translateError (error) {
      switch(error) {
        case 'password must contain only letters (a-z) and digits (0-9)': return 'Password must contain only letters (a-z) and digits (0-9)';
        case 'password must have a length between 8 and 20': return 'Password must have a length between 8 and 20';
        case 'user-password-incorrect': return 'Incorrect current password';
        default: return error;
      }
    },
  },
}
</script>


<template>
  <main>

    <div class="alerts">
      <div class="sidebar">
        
        <div class="menu">
          <a @click="setMy()" :class="{ active: isMy() }"><i class="fas fa-star"></i> My alerts</a>
          <a @click="setLiked()" :class="{ active: isLiked() }"><i class="fas fa-heart"></i> Liked alerts</a>
        </div>

      </div>
      <div class="content">
        
        <div class="results" v-if="promoted.length > 0">
          <h1 v-if="isMy()">My alerts</h1>
          <h1 v-if="isLiked()">Liked alerts</h1>
          <h1 v-if="isMore()">More alerts</h1>
          <ul class="promoted">
            <li v-for="item in paginated" :key="item.id">
              <router-link :to="{ name: 'visit', params: { code: item.code } }" target="_blank">{{ item.title }}</router-link>
              <div class="info">
                <span class="date">{{ item.date }}</span> / <span class="url">{{ item.url | shorten }}</span>
              </div>
              <ul><li v-for="sector in item.match" :key="sector">{{ sectorsMap[sector].name.en }}</li></ul>
              <a class="like" :class="{ liked: item.liked }" @click="updateLike(item.id, !item.liked)"><i class="fas fa-heart"></i> Like</a>
            </li>
          </ul>
          <a class="primary button" @click="more()" v-if="isPaginated && hasMore">See more</a>
          <a class="primary button" @click="less()" v-if="isPaginated && !hasMore">See less</a>
        </div>

        <div class="results" v-else>
          <h1 v-if="isMy()">My alerts</h1>
          <h1 v-if="isLiked()">Liked alerts</h1>
          <h1 v-if="isMore()">More alerts</h1>
          <div class="message">
            <p v-if="isMy()"><i class="fas fa-star"></i> Here you will find the alerts according to your preferences</p>
            <p v-if="isLiked()"><i class="fas fa-heart"></i> Here you will find the alerts you liked</p>
            <p v-if="isMore()"><i class="fas fa-list"></i> Here you will find more alerts according to your preferences</p>
          </div>
        </div>

      </div>
    </div>

  </main>
</template>

<script>
import sectors from '@/assets/js/sectors'

import APIPromotedService from '@/services/APIPromotedService'

export default {
  data () {
    return {
      sizeDefault: 10,
      view: {
        type: 'my',
        size: undefined,
      },
      promoted: [],
      sectorsMap: {},
    }
  },
  created () {
    this.view.size = this.sizeDefault;
    this.reloadList();
    for(const sector of sectors) {
      this.sectorsMap[sector.id] = sector;
    }
  },
  methods: {
    reloadList () {
      APIPromotedService.getPromotedList(this.view.type)
      .then(promoted => this.promoted = promoted);
    },
    more () {
      this.view.size = 0;
    },
    less () {
      this.view.size = this.sizeDefault;
    },
    updateLike (promotedId, like) {
      APIPromotedService.updateLike(promotedId, like).then(this.reloadList).catch(console.err);
    },
    setMy () {
      this.view.type = 'my';
    },
    isMy () {
      return this.view.type === 'my';
    },
    setLiked () {
      this.view.type = 'liked';
    },
    isLiked () {
      return this.view.type === 'liked';
    },
    setMore () {
      this.view.type = 'more';
    },
    isMore () {
      return this.view.type === 'more';
    },
  },
  computed: {
    formatted () {
      return this.promoted.slice().sort((a, b) => {
        if (a.date < b.date) {
          return 1;
        } else if (a.date > b.date) {
          return -1;
        } else {
          if (a.priority > b.priority) {
            return -1;
          } else if (a.priority < b.priority) {
            return 1;
          } else {
            return 0;
          }
        }
      }).map(item => {
        const fields = item.date.split('-');
        return { ...item, date: `${fields[2]}-${fields[1]}-${fields[0]}` }
      });
    },
    paginated () {
      return this.view.size === 0 || this.formatted.length <= this.view.size ? this.formatted : this.formatted.slice(0, this.view.size);
    },
    isPaginated () {
      return this.view.size < this.formatted.length;
    },
    hasMore () {
      return this.paginated.length < this.formatted.length;
    }
  },
  filters: {
    shorten (value) {
      value = value.replace('http://', '');
      value = value.replace('https://', '');
      value = value.replace(/\/.*/, '');
      value = value.replace(/:.*/, '');
      return value;
    }
  },
  watch: {
    'view.type' () {
      this.reloadList();
    }
  },
}
</script>
<template>
  <main>

    <h1>
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/img/observa-logo.png" alt="Observa">
      </router-link>
    </h1>

    <div class="bye">      
      <strong>Thank you, we hope you took advantage of your Observa account. You can come back at any time.</strong>    
    </div>

    <div class="bye">
      <router-link :to="{ name: 'home' }"><i class="fas fa-long-arrow-alt-left"></i> Back to home</router-link>
    </div>

  </main>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('logout');
  }
}
</script>
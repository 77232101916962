<template>
  <main>Redirecting...</main>
</template>

<script>
import APIPromotedService from '@/services/APIPromotedService'

export default {
  data () {
    return {
      code: this.$route.params.code,
    }
  },
  created () {
    if (this.code) {
      APIPromotedService.visit(this.code).then(url => {
        document.location.href = url;
      }).catch(() => {
        this.$router.push({ name: "root" });
      }); 
    }
  },
}
</script>
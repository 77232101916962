<template>
  <div class="reset">

    <div class="steps">
      <div class="step">
        <div class="number"><i class="fas fa-lock"></i></div>
        <div class="title">Credentials</div>
      </div>
      <div class="step active">
        <div class="number"><i class="fas fa-check"></i></div>
        <div class="title">Confirm</div>
      </div>
    </div>

    <div class="form">
      <div class="field">
        <label>Enter the confirmation code sent to {{ email }}</label>
        <input type="text" placeholder="Confirmation code..." v-model="code">
      </div>
      <div class="field">
        <a class="primary fluid button" @click="reset()" :class="{ loading: loadingReset }">Finish</a>
      </div>
    </div>
        

    <div class="error message" v-if="errors.messages.length > 0">
      <h4>The following errors occurred</h4>
      <ul>
        <li v-for="message in errors.messages" :key="message">{{ message }}</li>
      </ul>
    </div>

    <div class="message">
      <p>The code will only be sent if the account exists</p>
    </div>

    <div class="form">
      <a class="fluid button" @click="sendConfirmation()" :class="{ loading: loadingSend }">Resend confirmation code</a>
    </div>

    <router-link :to="{ name: 'login' }"><i class="fas fa-long-arrow-alt-left"></i> Back to login page</router-link>
    
  </div>
</template>

<script>
import APIAccountService from '@/services/APIAccountService'
import ErrorService from '@/services/ErrorService'

export default {
  props: [ 'data' ],
  data () {
    return {
      code: '',
      loadingReset: false,
      loadingSend: false,
      errors: ErrorService.init(),
    }
  },
  created () {
    if(!this.data.credentialsChecked) {
      this.$router.replace({ name: 'reset' });
    } else {
      this.sendConfirmation();
    }
  },
  methods: {
    sendConfirmation () {
      this.loadingSend = true;
      APIAccountService.sendResetConfirmation({ email: this.email })
      .then(() => {
        this.loadingSend = false;
        this.errors = ErrorService.init();
      })
      .catch(response => {
        this.loadingSend = false;
        this.errors = ErrorService.process(response, this.translateError);
      });
    },
    reset () {
      this.loadingReset = true;
      APIAccountService.reset({ ...this.data.credentials, code: this.code })
      .then(() => {
        this.loadingReset = false;
        this.$router.replace({ name: 'reset-done' }).catch(console.error);
      })
      .catch(response => {
        this.loadingReset = false;
        this.errors = ErrorService.process(response, this.translateError);
      });
    },
    translateError (error) {
      switch(error) {
        case 'email must be valid email': return 'Email is not valid';
        case 'password must contain only letters (a-z) and digits (0-9)': return 'Password must contain only letters (a-z) and digits (0-9)';
        case 'password must have a length between 8 and 20': return 'Password must have a length between 8 and 20';
        case 'code-incorrect': return 'Código de confirmación incorrecto';
        case 'code must contain only digits (0-9)': return 'Código de confirmación inválido';
        case 'code must have a length between 8 and 8': return 'Código de confirmación inválido';
        default: return error;
      }
    },
  },
  computed: {
    email() {
      return this.data.credentials.email;
    }
  },
}
</script>
<template>
  <div>Redirecting...</div>
</template>

<script>
export default {
  created () {
    if(this.$store.getters.getUser.name) {
      this.$router.push('/dashboard');
    } else {
      this.$router.push('/home');
    }
  }
}
</script>
<template>
  <main>

    <div class="settings">
      <div class="sidebar">

        <div class="image">
          <img src="https://observa.ovtt.org/static/avatar.png">
          <div class="name">{{ user.name}} {{ user.surname }}</div>
        </div>

        <div class="menu">
          <router-link :to="{ name: 'settings-user' }"><i class="fas fa-id-card"></i> Personal data</router-link>
          <router-link :to="{ name: 'settings-search' }"><i class="fas fa-heart"></i> Preferences</router-link>
          <router-link :to="{ name: 'settings-password' }"><i class="fas fa-lock"></i> Change password</router-link>
          <router-link :to="{ name: 'settings-delete' }"><i class="fas fa-trash"></i> Delete account</router-link>
        </div>

      </div>
      <div class="content">
        <router-view/>
      </div>
    </div>

  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TopMenu',
  computed: {
    ...mapGetters({ 
      user: 'getUser',
    }),
  },
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import axios from 'axios'

Vue.use(Vuex)

var logged = false;

function configureAxios() {  
  return axios.interceptors.response.use(response => response, error => {
    if (logged) {
      if (error.response.status === 401 || error.response.status === 403) {
        router.push({ name: 'login' }).catch(Promise.reject);
      }
    }
    return Promise.reject(error);
  });
}

function addToken(token) {
  if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function removeToken() {
  delete axios.defaults.headers.common['Authorization'];
}

export default new Vuex.Store({
  state: {
    user: {},
    token: null,
  },
  mutations: {
    login (state, { user, token }) {
      Object.assign(state, { user, token });
    },
    logout (state) {
      Object.assign(state, { user: {}, token: null });
    },
    update (state, { user }) {
      state.user.name = user.name;
      state.user.surname = user.surname;
    },
  },
  getters: {
    getUser: state => state.user,
    getToken: state => state.token,
    isAuthenticated: state => !!state.user,
  },
  actions: {
    init({ commit }) {
      const token = localStorage.getItem('token') || null;
      const user = token ? JSON.parse(localStorage.getItem('user')) : {};
      configureAxios();
      addToken(token);
      commit('login', { user, token });
      logged = !!token;
    },
    login({ commit }, { user, token }) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      addToken(token);
      commit('login', { user, token });
      logged = true;
    },
    logout({ commit }) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      removeToken();
      commit('logout');
      logged = false;
    },
    update({ commit }, { user }) {
      commit('update', { user });
    },
  },
  modules: {
  }
})

export default {

    init () {
      return { fields: {}, messages: new Set() };
    },
  
    process (response, translate) {
      const errors = this.init();
      if (response.status === 400) {
        for(const field in response.data) {
          errors.fields[field] = true;
          for(const message of response.data[field]) {
            errors.messages.add(translate(message));
          }
        }
      }
      errors.messages = [ ...errors.messages ];
      return errors;
    },
  
  }
  
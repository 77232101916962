<template>
  <div class="checkbox">
    <a @click="update()" :class="{ active: value }" tabindex="0">
      <i class="fas fa-check-square" v-if="value"></i>
      <i class="fas fa-square" v-else></i>
    </a>
    <div class="text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: Boolean,
  },
  mounted () {
    this.$el.addEventListener('keydown', (e) => {
      if(e.which === 32) this.update();
    });
  },
  methods: {
    update () {
      this.$emit('input', !this.value);
    }
  }
}
</script>

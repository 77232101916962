<template>
  <main>

    <div class="settings">
      <div class="sidebar">

        <date-picker v-model="view.date" :attributes="attributes" @update:to-page="pageChange" @dayclick="dayChange" locale="en" is-expanded ref="calendar"/>

        <div class="menu">
          <a @click="edit()"><i class="fas fa-plus"></i> Add alert</a>
          <a @click="setToday()" :class="{ active: isToday() }"><i class="fas fa-calendar"></i> Today</a>
          <a @click="setLast()" :class="{ active: isLast() }"><i class="fas fa-history"></i> Last modified</a>
          <a @click="setNext()" :class="{ active: isNext() }"><i class="fas fa-forward"></i> Next items</a>
        </div>

      </div>
      <div class="content">

        <h1>Alerts</h1>

        <ul class="promoted">
          <li v-for="item in promoted" :key="item.id" :class="{ priority: item.priority }">
            <a :href="item.url" target="_blank">{{ item.title }}</a>
            <div class="info">
              <span class="url">{{ item.url }}</span>
            </div>
            <a @click="edit(item.id)" class="action"><i class="fas fa-pencil-alt"></i> Edit</a>
            <a @click="remove(item.id)" class="action delete"><i class="fas fa-trash"></i> Delete</a>
            <ul>
              <li v-if="item.pending"><i class="fas fa-clock"></i></li>
              <li v-if="item.show"><i class="fas fa-eye"></i></li>
              <li v-for="sector in item.sectors" :key="sector">{{ sectorsMap[sector].name.en }}</li>
            </ul>
          </li>
        </ul>

      </div>
    </div>

  </main>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// import countries from '@/assets/js/countries'
import sectors from '@/assets/js/sectors'
// import topics from '@/assets/js/topics'

import APIPromotedService from '@/services/APIAdminPromotedService'
import Modal from '@/assets/js/modal'

import PromotedEditModal from '@/views/admin/promoted/PromotedEditModal'

export default {
  components: { DatePicker },
  data () {
    return {
      id: this.$route.query.id,
      today: new Date(),
      view: {
        date: undefined,
        sort: 0,
      },
      page: undefined,
      promoted: [],
      histogram: [],
      sectorsMap: {},
    }
  },
  created () {
    this.reloadList();
    for(const sector of sectors) {
      this.sectorsMap[sector.id] = sector;
    }
    if(this.id) {
       Modal.show(PromotedEditModal, { importId: this.id }, () => {
        this.reloadHistogram();
        this.reloadList();
      });
    }
  },
  methods: {
    edit ( promotedId ) {
      Modal.show(PromotedEditModal, { promotedId }, () => {
        this.reloadHistogram();
        this.reloadList();
      });
    },
    remove ( promotedId ) {
      APIPromotedService.deletePromoted(promotedId)
      .then(() => {
        this.reloadHistogram();
        this.reloadList();
      });
    },
    pageChange ( page ) {
      this.page = page;
      this.reloadHistogram();
    },
    dayChange () {
      this.reloadList();
    },
    reloadHistogram () {
      APIPromotedService.getPromotedHistogram( this.page.month, this.page.year )
      .then(histogram => this.histogram = histogram);
    },
    reloadList () {
      if (this.view.date) {
        var dd = String(this.view.date.getDate()).padStart(2, '0');
        var mm = String(this.view.date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = this.view.date.getFullYear();
        APIPromotedService.getPromotedList(`${yyyy}-${mm}-${dd}`, this.view.sort)
        .then(promoted => this.promoted = promoted);
      } else {
        APIPromotedService.getPromotedList(null, this.view.sort)
        .then(promoted => this.promoted = promoted);
      }
    },
    async setToday () {
      const calendar = this.$refs.calendar
      await calendar.move(this.today);
      this.view = { date: this.today, sort: 0 };
      Object.assign(this.view, { date: this.today, sort: 0 });
    },
    isToday () {
      return this.view.date === this.today;
    },
    setLast () {
      this.view = { date: null, sort: 0 };
    },
    isLast () {
      return !this.view.date && this.view.sort === 0;
    },
    setNext () {
      this.view = { date: null, sort: 1 };
    },
    isNext () {
      return !this.view.date && this.view.sort === 1;
    },
  },
  watch: {
    view () {
      this.reloadList();
    }
  },
  computed: {
    attributes () {
      return this.histogram.map(item => {        
        let color = '#e60000';
        if (item.count == 2) color = '#E67375';
        if (item.count == 1) color = '#E6B8B9';
        return { bar: { color: 'red', fillMode: 'solid', style: { backgroundColor: color  }}, dates: item.date, popover: { label: item.count + ' items found' }};
      });
    }
  },
}
</script>
<template>
  <div class="modal">

    <div class="modal-header" v-if="promotedId">Edit promoted</div>
    <div class="modal-header" v-else-if="importId">Import promoted</div>
    <div class="modal-header" v-else>Add promoted</div>

    <div class="modal-content">
      <div class="form">
        <div class="field">
          <checkbox v-model="promoted.force"> Force for all users</checkbox>
        </div>
        <div class="field">
          <checkbox v-model="promoted.university"> From University of Alicante</checkbox>
        </div>
        <div class="field">
          <checkbox v-model="promoted.priority"> Prioritize alert (appear on top)</checkbox>
        </div>
        <div class="field">
          <checkbox v-model="promoted.pending"> Pending revision</checkbox>
        </div>
        <div class="field">
          <checkbox v-model="promoted.show"> Show even if pending</checkbox>
        </div>
        <div class="field">
          <label>Title</label>
          <input type="text" placeholder="Title..." aria-label="Title" v-model.lazy="promoted.title">
        </div>
        <div class="field">
          <label>URL</label>
          <input type="text" placeholder="URL..." aria-label="URL" v-model.lazy="promoted.url">
        </div>
        <!-- <div class="field">
          <label>Summary</label>
          <textarea placeholder="Summary..." aria-label="Summary" v-model.lazy="promoted.summary"></textarea>
        </div> -->
        <div class="field">
          <label>Sectors</label>
          <select-buttons v-model="promoted.sectors" :options="sectorsI18N"/>
        </div>
        <div class="field">
          <label>Topics</label>
          <select-buttons v-model="promoted.topics" :options="topicsI18N"/>
        </div>
        <!-- <div class="field">
          <label>Countries</label>
          <select-buttons v-model="promoted.countries" :options="countriesI18N" :field-id="'code'"/>
        </div> -->
        <div class="field">
          <label>Dates</label>
          <div class="select-buttons" v-if="promoted.dates && promoted.dates.length > 0">
            <a v-for="date in promoted.dates" :key="date" @click="removeDate(date)">{{ date }}</a>
          </div>
          <div v-else>No dates specified</div>
        </div>
        <div class="field">
          <date-picker :value="[]" :attributes="attributes" @update:to-page="pageChange" @dayclick="dayChange" is-expanded/>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <a class="button" @click="cancel()">Cancel</a>
      <a class="primary button" @click="save()" :class="{ loading }">Save</a>
    </div>

  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import countries from '@/assets/js/countries'
import sectors from '@/assets/js/sectors'
import topics from '@/assets/js/topics'

import Modal from '@/assets/js/modal'
import ErrorService from '@/services/ErrorService'
import APIPromotedService from '@/services/APIAdminPromotedService'
import APIImportService from '@/services/APIAdminImportService'
import SelectButtons from '@/components/SelectButtons'
import Checkbox from '@/components/Checkbox'

export default {
  components: { SelectButtons, DatePicker, Checkbox },
  props: [ 'promotedId', 'importId' ],
  data () {
    return {
      histogram: [],
      promoted: { title: '', summary: '', url: '', force: false, university: false, pending: false, priority: true, show: false, sectors: [], topics: [], countries: [], dates: [] },
      language: 'en',
      loading: false,
      countries, sectors, topics,
      errors: ErrorService.init(),
    }
  },
  created () {
    if (this.promotedId) {
      APIPromotedService.getPromoted(this.promotedId).then(promoted => this.promoted = promoted)
    } else if (this.importId) {
      APIImportService.import(this.importId).then(promoted => this.promoted = promoted)
    }
  },
  computed: {
    sectorsI18N () {
      const sectors = [];
      this.sectors.forEach(sector => sectors.push({ id: sector.id, name: sector.name[this.language] }));
      return sectors.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    topicsI18N () {
      const topics = [];
      this.topics.forEach(topic => topics.push({ id: topic.id, name: topic.name[this.language] }));
      return topics.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    countriesI18N () {
      const countries = [];
      this.countries.forEach(country => countries.push({ code: country.code, name: country.name[this.language] }));
      return countries.sort((a, b) => a.name == b.name ? 0 : (a.name > b.name ? 1 : -1));
    },
    attributes () {
      const attributes = [];
      for (const item of this.histogram) {
        let color = '#e60000';
        if (item.count == 2) color = '#E67375';
        if (item.count == 1) color = '#E6B8B9';
        attributes.push({ bar: { color: 'red', fillMode: 'solid', style: { backgroundColor: color  }}, dates: item.date, popover: { label: item.count + ' items found' } });
      }
      for (const item of this.promoted.dates ) {
        attributes.push({ highlight: 'blue', dates: item, popover: { label: 'Selected date' } });
      }
      return attributes;
    }
  },
  methods: {
    save () {
      if (this.promotedId) {
        APIPromotedService.updatePromoted(this.promotedId, this.promoted).then(() => Modal.success());
      } else {
        APIPromotedService.addPromoted(this.promoted).then(() => Modal.success());
      }
    },
    cancel () {
      Modal.hide();
    },
    pageChange ( page ) {
      APIPromotedService.getPromotedHistogram( page.month, page.year )
      .then(histogram => this.histogram = histogram);
    },
    dayChange ( day ) {
      const value = day.date;
      var dd = String(value.getDate()).padStart(2, '0');
      var mm = String(value.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = value.getFullYear();
      const date = `${yyyy}-${mm}-${dd}`;
      const idx = this.promoted.dates.findIndex(d => d === date);
      if (idx >= 0) {
        this.promoted.dates.splice(idx, 1);
      } else {
        this.promoted.dates.push(date);
      }
    },
    removeDate ( date ) {
      const idx = this.promoted.dates.findIndex(d => d === date);
      if (idx >= 0) {
        this.promoted.dates.splice(idx, 1);
      }
    },
  },
}
</script>
<template>
  <div class="register">

    <div class="steps">
      <div class="step">
        <div class="number"><i class="fas fa-user"></i></div>
        <div class="title">Personal data</div>
      </div>
      <div class="step">
        <div class="number"><i class="fas fa-tasks"></i></div>
        <div class="title">Preferences</div>
      </div>
      <div class="step active done">
        <div class="number"><i class="fas fa-check"></i></div>
        <div class="title">Confirm</div>
      </div>
    </div>

    <div class="success confirm message">
      <i class="fas fa-check-circle"></i>
      <h2>Congratulations</h2>
      <p>Your account has been created successfully</p>
    </div>

    <router-link :to="{ name: 'login' }" class="ui primary fluid button">Go to login page</router-link>
    
  </div>
</template>

<script>
export default {
  props: [ 'user' ],
}
</script>